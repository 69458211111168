<template>
  <div>
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Organisation', route: 'Home' },
        { name: businessinfo.orgname, route: 'Home' },
        { name: 'Manage Business', route: 'Home' },
      ]"
    />
    <div class="placeholder">
      <div class="row">
        <div class="col-6">
          <div class="error" v-for="error in errors" :key="error">
            {{ error }}
          </div>
          <div class="success" v-for="message in messages" :key="message">
            {{ message }}
          </div>
          <form class="form" v-on:submit.prevent="UpdateBusiness">
            <label for="businessinfo.name">Business / Practice Name</label>
            <input
              type="text"
              class="input"
              v-model="businessinfo.name"
              readonly
            />
            <label for="businessinfo.address">Address</label>
            <textarea
              class="input left"
              v-model="businessinfo.address"
              rows="4"
              required
            ></textarea>
            <label for="businessinfo.postcode">Postcode</label>
            <input
              type="text"
              class="input"
              v-model="businessinfo.postcode"
              required
            />
            <label for="businessinfo.contact_email">Contact Email</label>
            <input
              type="email"
              class="input"
              v-model="businessinfo.contact_email"
              required
            />
            <label for="businessinfo.contact_telephone"
              >Contact Telephone</label
            >
            <input
              type="tel"
              class="input"
              v-model="businessinfo.contact_telephone"
              required
            />
            <label for="businessinfo.logo">Logo / Badge</label>
            <input
              type="file"
              class="input"
              accept="image/*"
              @change="logoUpload"
            />
            <br />
            <br />
            <input
              v-if="[1, 3].includes(role)"
              type="submit"
              class="btn btn-green"
              value="Save Changes"
            />
            <div
              v-if="[1, 3].includes(role)"
              v-on:click="OpenAddCreditsModal()"
              class="btn btn-orange"
              style="margin-left: 20px; float: right"
            >
              <i class="icon icon-basket"></i> Add Credits
            </div>
          </form>
        </div>
        <div class="col-6">
          <div class="avail-test-placeholder">
            <div class="tests">{{ businessinfo.credits }}</div>
            <div class="title">Available tests</div>
          </div>
          <br />
          <table class="table">
            <thead>
              <tr>
                <td>Name</td>
                <td>Email</td>
                <td colspan="2">Role</td>
              </tr>
            </thead>
            <tbody>
              <tr v-if="[1, 3].includes(role)">
                <td colspan="1">
                  <label for="adduser.email">Email Address</label>
                  <input
                    id="adduser.email"
                    type="email"
                    class="input"
                    v-model="adduser.email"
                    placeholder="example@example.com"
                  />
                </td>
                <td>
                  <label for="adduser.role">Role</label>
                  <select
                    class="input"
                    v-model="adduser.role"
                    id="adduser.role"
                  >
                    <option value="1">Member</option>
                    <option value="2">Lead Member</option>
                    <option value="3">Manager</option>
                  </select>
                </td>
                <td colspan="2">
                  <button v-on:click="inviteUser" class="btn btn-green">
                    Invite User <i class="icon icon-pen"></i>
                  </button>
                </td>
              </tr>
              <tr v-for="(user, index) in users" :key="user">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>{{ roleTypes(user.role) }}</td>
                <td v-if="[1, 3].includes(role)">
                  <div
                    class="btn btn-orange"
                    v-on:click="RemoveUserFromBusiness(user.id, index)"
                  >
                    <span class="icon icon-delete"></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="addcredits-modal" class="modal">
      <div class="modal-content">
        <div class="row">
          <span class="close">&times;</span>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="error" v-for="error in errors" :key="error">
              {{ error }}
            </div>
            <div class="success" v-for="message in messages" :key="message">
              {{ message }}
            </div>
            <form class="form" v-on:submit.prevent="AddCreditsToBusiness">
              <label for="credits"
                >How many credit's would you like to add?</label
              >
              <input
                type="number"
                v-model="addcredits.credits"
                class="input"
                min="1"
                max="999"
              />
              <br />
              <small
                >NOTE: Adding credits will incur a charge** to
                {{ businessinfo.orgname }}.</small
              >
              <br />
              <br />
              <input
                v-if="[1, 3].includes(role)"
                type="submit"
                class="btn btn-green"
                value="Save Changes"
              />
            </form>
          </div>
          <div class="col-6">
            <div class="avail-test-placeholder">
              <div class="tests">
                {{ businessinfo.credits + addcredits.credits }}
              </div>
              <div class="title">Available tests</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Navigation from "../components/Navigation.vue";
export default {
  name: "Business_Manage",
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      businessinfo: "",
      organisation: false,
      organisationinfo: {},
      users: [],
      messages: [],
      errors: [],
      adduser: {
        email: "",
        role: 1,
      },
      addcredits: {
        credits: 0,
      },
      role: 0,
    };
  },
  components: {
    Navigation,
  },
  computed: {},
  methods: {
    GetBusinessInfo() {
      this.store
        .dispatch("Organisation/GetBusinessInfo", {
          id: this.$route.params.id,
          bid: this.$route.params.bid,
        })
        .then((data) => {
          this.businessinfo = data.data;
          this.businessinfo.logo = false;
          this.businessinfo.change_logo = false;
          if (data.organisation) {
            this.organisation = true;
            this.organisationinfo = data.organisation;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetBusinessUsers() {
      this.store
        .dispatch("Organisation/GetBusinessUsers", {
          id: this.$route.params.id,
          bid: this.$route.params.bid,
        })
        .then((data) => {
          this.users = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    inviteUser() {
      this.store
        .dispatch("Organisation/InviteBusinessUser", {
          id: this.$route.params.id,
          bid: this.$route.params.bid,
          email: this.adduser.email,
          role: this.adduser.role,
        })
        .then((data) => {
          console.log(data);
          this.messages = data.messages;
        })
        .catch((err) => {
          console.log(err);
          this.errors = err.messages;
        });
    },
    roleTypes(int) {
      switch (int) {
        case 1:
          return "Member";
        case 2:
          return "Lead Member";
        case 3:
          return "Manager";
        case 4:
          return "Owner";
        default:
          return "N/A";
      }
    },
    logoUpload(e) {
      this.businessinfo.change_logo = true;
      this.businessinfo.logo = e.target.files[0];
    },
    UpdateBusiness() {
      let id = this.$route.params.id;
      this.store
        .dispatch("Organisation/UpdateBusinessInfo", {
          id: id,
          bid: this.$route.params.bid,
          address: this.businessinfo.address,
          postcode: this.businessinfo.postcode,
          contact_email: this.businessinfo.contact_email,
          contact_telephone: this.businessinfo.contact_telephone,
          logo: this.businessinfo.logo,
          change_logo: this.businessinfo.change_logo,
        })
        .then((data) => {
          console.log(data);
          this.messages = data.messages;
        })
        .catch((err) => {
          console.log(err);
          this.errors = err.messages;
        });
    },
    RemoveUserFromBusiness(user, index) {
      this.store
        .dispatch("Organisation/RemoveUserFromBusiness", {
          id: this.$route.params.id,
          bid: this.$route.params.bid,
          user_id: user,
        })
        .then((result) => {
          this.messages = result.messages;
          this.errors = [];
          this.users.splice(index, 1);
        })
        .catch((err) => {
          this.errors = err.messages;
          this.messages = [];
        });
    },
    GetOrganisationRole() {
      let orgs = this.store.state["User"].organisations;
      orgs.map((row) => {
        if (row.organisation_id == this.$route.params.id) {
          this.role = row.role;
        }
      });
    },
    OpenAddCreditsModal() {
      // Get the modal
      var modal = document.getElementById("addcredits-modal");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("close")[0];

      // When the user clicks on the button, open the modal
      modal.style.display = "block";

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
      };

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };
    },
    AddCreditsToBusiness() {
      this.store
        .dispatch("Organisation/AddCreditsToBusiness", {
          id: this.$route.params.id,
          bid: this.$route.params.bid,
          ...this.addcredits,
        })
        .then((result) => {
          this.messages = result.messages;
          this.errors = [];
        })
        .catch((err) => {
          this.errors = err.messages;
          this.messages = [];
        });
    },
  },
  mounted() {
    this.GetBusinessInfo();
    this.GetBusinessUsers();
    this.GetOrganisationRole();
  },
};
</script>

<style>
.btn-small {
  height: auto;
  width: auto;
  font-size: 12px;
  padding: 5px;
}
.avail-test-placeholder {
  align-content: center;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}
.tests {
  position: absolute;
  background: #ffffff;
  color: #4d4d4d;
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px;
  border: 2px solid #e4e7ee;
  border-radius: 100%;
  font-weight: bolder;
  font-size: 40px;
  display: block;
  z-index: 2;
  width: auto;
  height: auto;
  min-width: 80px;
  min-height: 80px;
  line-height: 80px;
  text-align: center;
}
.title {
  background: rgba(53, 187, 164, 19%);
  color: #35bba4;
  font-family: Arial, Helvetica, sans-serif;
  padding: 20px;
  font-weight: bold;
  border-radius: 10px;
  font-size: 30px;
  display: block;
  margin-top: 85px;
  line-height: 67px;
  height: 40px;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  text-align: center;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>